import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { Image_PATH } from '../../config/constant';
import { toast } from 'react-toastify';
import OwlCarousel from "react-owl-carousel";
export default function Header() {

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };
  
  const [banners,setBanners]=useState([]);
  const [settingData,setSettingsData]=useState({});
  useEffect(()=>{
    getBannerList();
    getSettings();
  },[]);

  const getBannerList=async ()=>{
    ApiService.post('banner/get-data',{page:1,limit:6000,status:'1',type:'3'}).then((data)=>{
      if(data?.Banners){
        setBanners(data.Banners);
      }else{
        setBanners([]);
      }
    }).catch((err)=>{
    //  toast.error(err?.response?.data);
    })
  }

  const getSettings=async ()=>{
    ApiService.get('settings/1').then((data)=>{
      if(data?.data){
        setSettingsData(data.data);
      }else{
        setSettingsData({});
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }


  const [isSticky, setIsSticky] = useState(false);
  const offcanvasHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (offcanvasHeaderRef.current) {
        // alert(window.scrollY)
        // alert(offcanvasHeaderRef.current.offsetTop)
        // Check if we have scrolled past the offcanvas header
        if (window.scrollY > offcanvasHeaderRef.current.offsetTop) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const options1 = {
    items: 1, // Only show one item at a time
    loop: true, // Allow looping
    margin: 10, // Add some margin between items
    responsive: {
      0: {
        items: 1, // 1 item on smaller screens
      },
      600: {
        items: 1, // 1 item on medium screens
      },
      1000: {
        items: 1, // 1 item on larger screens
      },
    },
    nav: true, // Enable navigation buttons
    dots: false, // Enable dots navigation
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set the time between transitions (in milliseconds)
    autoplayHoverPause: true, // Pause autoplay on hover
  };

  return (
    <>
    <div className="mobile-top-head">
      <div className="container-fluid">
        <div className="top-heder-call-mail">
          <div className="top-header-call">
            <a href="#">
              <i className="fa fa-phone" /> {settingData?.mobile}
            </a>
          </div>
          <div className="top-header-mail">
            <a href="#">
              <i className="fa fa-envelope" /> {settingData?.email}
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* header are start  */}
    <header>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          {/* top  header  start */}
          <div className="top-header row">
            <div className="col-lg-3 col-md-4 col-6">
              <Link className="navbar-brand" to="/">
                <img src="assets/images/logo.png" alt="" width="100%" />
              </Link>
            </div>
            <div className="mobile-hide-contact col-lg-9 col-md-8">
              <div className="row">
                <div className="col-xl-2 col-lg-1 col-md-0" />
                <div className="col-lg-6 col-md-7">
                  <div className="top-head-banner">
                    <div className="slider">
                      {banners &&
                                            banners?.length > 0 &&
                                              (
                                        <OwlCarousel
                                        {...options1}
                                          className="owl-carousel owl-theme"
                                          
                                        >
                                          {banners &&
                                            banners?.length > 0 &&
                                            banners
                                              ?.map((tm,k) => {
                                                return (
                                                  <div className="item" key={k}>
                                                    { tm?.url ?  <Link to={`${tm?.url}`}>
                                                    <img
                                                      src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                      alt={tm?.alt_image}
                                                      width="100%"
                                                    />
                                                    </Link> : <img
                                                      src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                      alt={tm?.alt_image}
                                                      width="100%"
                                                    /> }
                                                  </div>
                                                );
                                              })}
                                          {/* <div className="item">
                                        <img src="assets/images/b-2.jpg" alt="" width="100%" />
                                      </div>
                                      <div className="item">
                                        <img src="assets/images/b-3.jpg" alt="" width="100%" />
                                      </div> */}
                                        </OwlCarousel>
                                              )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-5">
                  <div className="top-header-contact">
                    <div className="comment-icon">
                    <a href={`tel:${settingData?.mobile}`}> <img src="assets/images/Capture.PNG" alt="" /></a>
                    </div>
                    <div className="number">
                      <a href={`tel:${settingData?.mobile}`}>
                        <h6>24x7 Helpline</h6>
                        <h5>{settingData?.mobile}</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleOffcanvas}
                aria-controls="staticBackdrop"
                aria-expanded={isOffcanvasOpen ? 'true' : 'false'}
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
          </div>
        </div>
        {/* top  header  end   */}
        <div
          className={`offcanvas offcanvas-start ${isOffcanvasOpen ? 'show' : ''}`}
          tabIndex="-1"
          id="staticBackdrop"
          aria-labelledby="staticBackdropLabel"
          style={{
            visibility: isOffcanvasOpen ? 'visible' : 'hidden',
            transition: 'visibility 0.5s, transform 0.5s ease-in-out',
            transform: isOffcanvasOpen ? 'translateX(0)' : 'translateX(-100%)',
          }}
        >
          <div className={`offcanvas-header ${isSticky ? 'sticky' : ''}`} ref={offcanvasHeaderRef}>
            <img src="assets/images/logo-white.png" alt="" width="50%" />
            <button
               type="button"
               className="btn-close"
               onClick={closeOffcanvas}
               aria-label="Close"
            />
          </div>
          <div className="container-fluid">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="about-us">
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="india-tour">
                  India Tour{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="international-tour">
                  International Tour{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="theme-base">
                  Theme Based{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="fixed-departures">
                  Fixed departure{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="payment">
                  Payment{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="guest-feedback">
                  Guest Feedback{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeOffcanvas} className="nav-link" to="contact-us">
                  Contact Us{" "}
                </Link>
              </li>
              <div className="social-link-mobile">
                <div className="link-social">
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </div>
                <div className="link-social">
                  <a href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </div>
                <div className="link-social">
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </div>
                <div className="link-social">
                  <a href="#">
                    <i className="fa fa-youtube-play" />
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    {/* header-area end  */}
    </>
  )
}
