import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { Image_PATH } from '../../config/constant';

export default function Terms() {
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
    useEffect(() => {
      getMeta();
    }, []);

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "terms",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

    useEffect(() => {
      // If metaTags is not empty, inject it into the head
      if (metaTags) {
        const head = document.head;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = metaTags;
        // Inject all meta tags into the head
        Array.from(tempDiv.children).forEach((child) => {
          head.prepend(child);
        });
  
        const body = document.body;
        const tempDivbody = document.createElement("div");
        tempDivbody.innerHTML = page?.footer;
        // Inject all meta tags into the head
        Array.from(tempDivbody.children).forEach((child) => {
          body.append(child);
        });
      }
    }, [metaTags]);
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src={`${Image_PATH}page/${page?.banner}`} alt={page?.alt_banner} width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  <div className="term-condition-sec py-5">
  <div className="container-fluid px-lg-5">
    <div className="title">
      <h2>TERMS &amp; CONDITIONS</h2>
    </div>
    <div className="terms-data mt-3">
      <p>
        <span style={{ color: "#ff6600" }}>
          <strong>TERMS &amp; CONDITIONS: -</strong>
        </span>
      </p>
      <ul>
        <li>
          The confirmation is subjects to availability. Check in and checkout
          time as per hotel policy.
        </li>
        <li>
          Rooms are subject to the availability. No reservation has been made
          till date. This is just a quotation. Meals Timing must be followed as
          per the instructed time of the hotels. For any un-availed meals we
          shall not be responsible.
        </li>
        <li>
          Please carry your valid identity proof card containing your
          photographs at it is required at the airport and during check in to
          hotels.
        </li>
        <li>
          For early check in and late checkout payments are to be settled
          directly by the guest at the hotel.
        </li>
        <li>
          The above Quotation is only an offer and not confirmation of your
          bookings. If we are not able to get the original hotels, shall provide
          equivalent/ alternative hotels.
        </li>
        <li>
          Minto Holidays does not have any hidden charges except your lunch, and
          personal expenses. During the Journey if someone asks for extra money
          you may inform us and we will not be responsible if you pay the amount
          without our concern.
        </li>
        <li>
          No refund will not be made for any unused accommodation, missed meals,
          transportation sightseeing tours or any other service due to Bad
          weather, ill health, Strike, Road blocks or any other factor beyond
          our Any Natural calamities Minto Holidays will not be liable for the
          same.
        </li>
        <li>
          Before confirming your bookings kindly personally check all hotels
          Website, reviews &amp; other services in internet. We have already
          provided the hotels name destinations wise. If the given hotels are
          not suitable then please ask for the upgraded hotel options.
        </li>
        <li>
          If during your travel you will face any kind of problem regarding your
          Hotels &amp; transport, you all are highly requested to contact to
          your tour executive same time so that he/she can resolve the problem.
          After
        </li>
        <li>
          reaching your home if you will complaint for anything we won't be
          unable to solve the issue No accommodation, meal or cost of transport
          would be paid or reimbursed or compensated.
        </li>
        <li>
          All buffet meal/ Room Service will be provided as per hotel policy
          Control. Such unused items are neither refundable nor exchangeable. No
          discount will be provided after confirmation of the package.
        </li>
        <li>
          Any dispute relating to or arising out of all or any one of the above
          stated terms and conditions shall be subject to the exclusive
          Jurisdiction of Courts at New Delhi, India.
        </li>
        <li>
          Kindly read all above T&amp;C and All remarks carefully before making
          your bookings. Once you made your booking, you bound to accept these
          Terms and Conditions.
        </li>
      </ul>
      <h5>RATES</h5>
      <ul>
        <li>
          All rates offering from Minto subject to change without any prior
          update. All Rates subject to availability
        </li>
        <li>Trains/Flight/Volvo ticket Subject to availability</li>
        <li>
          All quoted flight Tickets rates are flexible can be change at the time
          of booking........
        </li>
      </ul>
    </div>
  </div>
</div>

  {/* Modal */}
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header" id="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close modal-button-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="row">
          <div className="col-lg-6" id="mobile-modal-hide">
            <div className="modal-body">
              <div className="modal-banner-content">
                <div className="modal-banner">
                  <div
                    id="carouselExampleRide"
                    className="carousel slide carousel-fade"
                    data-bs-ride="true"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/1.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/2.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/3.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <div className="modal-features">
                  <div className="why-features row py-3">
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/traveler.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Happy Traveler</span>
                      </div>
                    </div>
                    <div
                      className="features-box col-4"
                      id="features-box-border"
                    >
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/loyalty.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Tours Success</span>
                      </div>
                    </div>
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/experience.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Positives Review </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="last-line">
                    <p className="text-center">
                      <b>Excellent!</b>{" "}
                      <span>
                        {" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle-o" />{" "}
                      </span>{" "}
                      4.5 Rating Out of 5.0 Based On 526 reviews.
                    </p>
                  </div>
                </div>
                <div className="modal-contact">
                  <div className="call-modal">
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-phone" /> Call Us :{" "}
                        </span>{" "}
                        +91-1234567890
                      </a>
                    </h6>
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-envelope" /> Send Us Mail :{" "}
                        </span>{" "}
                        info@mintoholidays.com
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6" id="pop-right">
            <div className="modal-body" id="right-body">
              <div className="popup-form">
                <h5>Get in touch with us</h5>
                <form action="">
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Enter Phone no."
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Id."
                    />
                  </div>
                  <div className="col mb-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Enter Tour Details"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col- mb-3">
                    <button className="btn btn-submit">Submit Enquiry</button>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                  </div>
                </form>
              </div>
              <div className="get-whatsapp">
                <a href="">
                  <span>
                    <img src="assets/images/icons/whatsapp.png" alt="" />
                  </span>
                  <h6>Get itenary On Whatsapp</h6>
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}