import React, { useEffect, useState } from 'react'
import ApiService from '../../services/apiService';
import { toast } from "react-toastify";
import $ from 'jquery';
import { Image_PATH } from '../../config/constant';
import Select from 'react-select';
import { Link } from 'react-router-dom';
export default function Slider({destination,themes}) {
const [slider,setSlider]=useState([]);
const [selectDestination,setSelectDestination]=useState([]);
const [selectTheme,setSelectTheme]=useState([]);
const [filterForm, setFilterForm] = useState({
    destination_id: 0,
    theme_id: 0,
  });
 useEffect(()=>{
    if(destination.length>0){
      let dest=[]
      destination?.map((d)=>{
        dest.push({value: d?.id , label : d?.name })
      })
        setSelectDestination(dest);
    }
  },[destination])

  useEffect(()=>{
    if(themes.length>0){
      let theme=[]
      themes?.map((d)=>{
          theme.push({value: d?.id , label : d?.name })
        })
        setSelectTheme(theme);
    }
  },[themes])

  useEffect(()=>{
    getSliderList();
  },[]);
  
  const getSliderList=async ()=>{
    ApiService.get('sliders/st',{page:1,limit:50,status:'1'}).then((data)=>{
      if(data?.data){
        setSlider(data.data);
      }else{
        setSlider([]);
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }

  const setChangeEvent = async (e, name) => {
    setFilterForm((prev) => {
      return {
        ...prev,
        [name]: e?.value,
      };
    });
  };

  const getPackage = () => {
    if(destination && destination?.length){
    let destination1 = destination?.filter(
      (dt) => dt?.id === filterForm?.destination_id
    );
    let theme_id = themes?.filter((dt) => dt?.id === filterForm?.theme_id);
    if(theme_id?.length){
      window.location.href = `/package-list/filter/${destination1?.[0]?.url}/${theme_id?.[0]?.url}`;
    }else
      window.location.href = `/package-list/filter/${destination1?.[0]?.url}`;
  }
  };

  return (
    <>
        {/* banner section  */}
  <section className="banner-section">
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade desktop-banner"
      data-bs-ride="carousel"
      data-bs-interval="2000"
    >
      <div className="carousel-inner">
        { slider && slider.length>0 && slider?.map((s,k)=>{
        return(
        <div className={k===0 ? "carousel-item active" : "carousel-item"} key={k}>
          <Link to={s?.url ? s?.url : '#'}>
          <img src={`${Image_PATH}sliders/${s?.image_url}`} className="d-block w-100" alt={s?.alt_image} />
          <div className="banner-content">
            <div className="container-fluid">
              <div className="banner-title">
                { s?.text1 ? <h5>{s?.text1}</h5> : ''}
                { s?.text2 ? <h2>{s?.text2}</h2> : ''}
                { s?.text3 ? <h3>{s?.text3}</h3> : ''}
                { s?.text4 ? <p>{s?.text4}</p> : ''}
                {/* { s?.url ? <a href={s?.url}>Book A Trip</a> : ''} */}
              </div>
            </div>
          </div>
          </Link>
        </div>
        )
        })}
        
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-fade mobile-banner"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
      { slider && slider.length>0 && slider?.filter((s)=>s?.image_url2)?.map((s,k)=>{
        return(
        <div className={k===0 ? "carousel-item active" : "carousel-item"} key={k}>
          <img
            src={`${Image_PATH}sliders/${s?.image_url2}`}
            className="d-block w-100"
            alt={s?.alt_image_mobile}
          />
          <div className="banner-content"></div>
        </div>
         )
        })}
        {/* <div className="carousel-item">
          <img
            src="assets/images/mobile-banner-2.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content"></div>
        </div>
        <div className="carousel-item">
          <img
            src="assets/images/mobile-banner-3.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content"></div>
        </div> */}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <div className="search-form">
      <div className="search-form-box">
        <div className="form">
          <div className="form-inputs">
            <div className="search-form-input-1" id="first-select">
              <i className="fa fa-map-marker" />
              <Select
                id="Cityselect"
                options={selectDestination}
                onChange={(e) => setChangeEvent(e, "destination_id")}
                placeholder="Select..."
              />
            </div>
            <div className="search-form-input-2" id="second-select">
              <i className="fa fa-flag-o" />
              <Select
                id="Tourselect"
                options={selectTheme}
                onChange={(e) => setChangeEvent(e, "theme_id")}
                placeholder="Select..."
              />
            </div>
            <div className="search-form-button">
              <button type='button' onClick={() => getPackage()} className="btn btn-search text-light">
                Search <i className="fa fa-send-o" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* banner section end  */}
    </>
  )
}
