import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { Image_PATH } from '../../config/constant';

export default function PaymentPolicy() {
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
    useEffect(() => {
      getMeta();
    }, []);

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "payment_policy",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

    useEffect(() => {
      // If metaTags is not empty, inject it into the head
      if (metaTags) {
        const head = document.head;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = metaTags;
        // Inject all meta tags into the head
        Array.from(tempDiv.children).forEach((child) => {
          head.prepend(child);
        });
  
        const body = document.body;
        const tempDivbody = document.createElement("div");
        tempDivbody.innerHTML = page?.footer;
        // Inject all meta tags into the head
        Array.from(tempDivbody.children).forEach((child) => {
          body.append(child);
        });
      }
    }, [metaTags]);
  return (
    <>
    <section className="banner-section">
      <div className="banner">
        <img src={`${Image_PATH}page/${page?.banner}`} alt={page?.alt_banner} width="100%" />
      </div>
    </section>
    {/* banner section end  */}
    <>
  {/* Hello world */}
  <div className="term-condition-sec py-5">
    <div className="container-fluid px-lg-5">
      <div className="title">
        <h2>Payment Policy</h2>
      </div>
      <div className="terms-data mt-3">
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>Payment Policy - Minto Holidays Private Limited</strong>
          </span>
        </p>
        <p>
          At Minto Holidays Private Limited, we strive to make your booking
          experience smooth and hassle-free. Please find our payment policy
          outlined below:
        </p>
        <h5>1. Booking Confirmation</h5>
        <ul>
          <li>
            To confirm your booking, an advance payment of [30%] of the total
            package cost is required at the time of booking.
          </li>
          <li>
            The remaining balance must be cleared as per the payment schedule
            mentioned in your booking confirmation.
          </li>
        </ul>
        <h5>2. Payment Schedule</h5>
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>Domestic Packages:</strong>
          </span>
        </p>
        <ul>
          <li>30% advance at the time of booking.</li>
          <li>70% balance before [19-16] days of departure.</li>
        </ul>
        <p>
          <span style={{ color: "#ff6600" }}>
            <strong>International Packages:</strong>
          </span>
        </p>
        <ul>
          <li>60% advance at the time of booking.</li>
          <li>40% balance before [30] days of departure.</li>
        </ul>
        <p>
          For last-minute bookings made within [30] days of departure, full
          payment is required at the time of booking.
        </p>
        <h5>3. Mode of Payment</h5>
        <p>We accept the following payment methods:</p>
        <ul>
          <li>Bank Transfer (NEFT/RTGS/IMPS)</li>
          <li>Credit/Debit Cards</li>
          <li>UPI</li>
          <li>Cash (as per government guidelines)</li>
          <li>
            Failure to adhere to the payment schedule may result in booking
            cancellation, with applicable penalties.
          </li>
        </ul>
        <p>
          For any queries regarding payments, please contact our team at{" "}
          <strong>[+91-8800542270].</strong>
        </p>
      </div>
    </div>
  </div>
</>

  </>
  )
}
