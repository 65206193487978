import React from 'react'

export default function BookingThanks({isModalVisible,setIsModalVisible}) {
    const closeModal=()=>{
        window.location.href="/"
        setIsModalVisible(false)
    }
  return (
    <div
  className={`modal fade ${isModalVisible ? "show" : ""}`}
  id="exampleModal1"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  style={{ display: isModalVisible ? "block" : "none" }}
>
  <div className="modal-dialog" id="modal-dialog-preview">
    <div className="modal-content">
   <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img
                            src="assets/images/logo.png"
                            alt="Minto Holidays Logo"
                            style={{ maxWidth: "150px", marginBottom: "20px" }}
                        />
                    </div>
                    <div className="col-md-12" >
                        Thank You for Booking with Minto Holidays! Your booking is confirmed, and we’re excited to be part of your journey. Our team is here to ensure you have a smooth and memorable experience.
                        <br />
                        <br />
                        Thanks & Regards
                        <br />
                        — Minto Holidays Team —
                    </div>
                    </div>
         
          </div>
        {/* <div className="traveller-informations">
          <h5>Travellers Information</h5>
        </div>
        <h5 className="preview-gurest">Guests List</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Gender</th>
              <th scope="col">Name</th>
              <th scope="col">Age</th>
            </tr>
          </thead>
          <tbody>
            {guestCount && guestCount?.length>0 && guestCount?.map((g,k)=>{
                return(
                <tr key={k}>
                    <td>{g?.gender}</td>
                    <td>{g?.name}</td>
                    <td>{g?.age} Years</td>
                </tr>
                )
            })}
            
          </tbody>
        </table> */}
        
      
      <div className="modal-footer">
        <button onClick={()=>closeModal()} type="button" className="btn btn-danger">
          Ok
        </button>
       
      </div>
    </div>
  </div>
</div>

  )
}
