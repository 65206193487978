/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import PreviewBooking from "./PreviewBooking";
import BookingThanks from "./BookingThanks";
export default function Checkout() {
  const { url, tourtype_id, person } = useParams();
  const [packages, setPackages] = useState({});
  const [guestCount, setGuestCount] = useState([
    { name: "", gender: "", age: "" },
  ]);
  const [settingData, setSettingsData] = useState({});
  const [checkOut, setCheckout] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    from_date: "",
    // to_date: "",
    adult: "",
    children: 0,
    name: "",
    phone: "",
    email: "",
    country: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isThanksModalVisible, setIsThankModalVisible] = useState(false);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [loading, setLoading] = useState(true);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = new Date().getMonth(); // Get current month index (0-11)
  let result = [];

  for (let i = 0; i < 12; i++) {
    let monthIndex = (currentMonth + i) % 12; // Wrap around using modulo
    result.push({ month: months[monthIndex], index: monthIndex + 1 }); // Add month and its index (1-based)
  }
  console.log(result, "rrrrrrrrrrrrrrrrrrrrrrrrr");
  useEffect(() => {
    getSettings();
  }, [url]);

  const getSettings = async () => {
    ApiService.get("settings/1")
      .then(async (data) => {
        if (data?.data) {
          setSettingsData(data.data);
        } else {
          setSettingsData({});
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    if (url && !Object.keys(packages).length && Object.keys(settingData).length)
      getPackageById();
  }, [settingData]);

  const getPackageById = async () => {
    setLoading(true);
    ApiService.get(`package/package-details/${url}`)
      .then((data) => {
        setLoading(false);
        if (data?.data) {
          let isPersonPrice = 1;
          let packageDataPrice = data?.data?.package_prices?.filter(
            (p) =>
              +p?.tourtype_id === +tourtype_id && +p?.person === (+person || 1)
          );

          if (!packageDataPrice?.length && !tourtype_id && !person) {
            packageDataPrice = [
              data?.data?.package_prices?.reduce(
                (min, p) => (p.offer_price < min.offer_price ? p : min),
                data?.data?.package_prices[0]
              ),
            ];
            // tourtype_id=packageDataPrice?.[0]?.tourtype_id;
          }
          console.log(
            packageDataPrice,
            "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
            packages?.package_prices
          );
          if (!packageDataPrice?.length) {
            packageDataPrice = data?.data?.package_prices?.filter(
              (p) => +p?.tourtype_id === +tourtype_id && +p?.person === 1
            );
            isPersonPrice = person;
          }
          data["data"]["tourtype_id"] = packageDataPrice?.[0]?.Tourtype?.id;
          data["data"]["price"] = packageDataPrice?.[0]?.price;
          data["data"]["offer_price"] = packageDataPrice?.[0]?.offer_price;
          data["data"]["base_price"] =
            data["data"]?.offer_price *
              (bookingDetails?.children ? bookingDetails?.children : 0) +
            data["data"]?.offer_price *
              (bookingDetails?.adult ? bookingDetails?.adult : isPersonPrice);
          data["data"]["tax"] =
            (data["data"]["base_price"] * settingData?.gst) / 100;
          setPackages({ ...data?.data });
          setPage(data?.data);
          setMetaTags(data?.data?.header);
          setBookingDetails((prev) => {
            return {
              ...prev,
              // from_date: data?.data?.from_date,
              adult: person,
              // to_date: data?.data?.to_date,
            };
          });
          let guestperson = [];
          for (let i = 0; i < (person || 1); i++) {
            guestperson.push({ name: "", gender: "", age: "" });
          }
          setGuestCount(guestperson);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const addMoreGuest = async () => {
    let guestCountTemp = guestCount;
    guestCountTemp.push({ name: "", gender: "", age: "" });
    setGuestCount([...guestCount]);
    setBookingDetails((prev) => {
      return {
        ...prev,
        adult:  +prev?.adult + 1 ,
      };
    });
    // setPackages((prev) => {
    //   return {
    //     ...prev,
    //     person: +prev?.person + 1,
    //   };
    // })
  };

  const removeGuest = async (index) => {
    let guestCountTemp = guestCount;
    guestCountTemp.splice(index, 1);
    setGuestCount([...guestCount]);
    setBookingDetails((prev) => {
      return {
        ...prev,
        adult: prev?.adult>1 ? +prev?.adult - 1 : 1,
      };
    });

    // setPackages((prev) => {
    //   return {
    //     ...prev,
    //     person: prev?.person>1 ? +prev?.person - 1 : 1,
    //   };
    // })

  };

  const changeFiled = (e, name, index) => {
    let guestCountTemp = guestCount;
    let xx = guestCountTemp.find((g, k) => k === index);
    xx[name] = e?.target?.value;
    setGuestCount([...guestCount]);
  };

  const changeBookingFiled = (e, name) => {
    if (name === "month") {
      const currentDate = new Date(),
        month = e?.target?.value;
      let filterDates = packages?.package_dates.filter((dateStr) => {
        const date = new Date(dateStr?.from_date); // Convert string to Date object
        return date > currentDate && +date.getMonth() + 1 === +month;
      });

      setPackages((prev) => {
        return {
          ...prev,
          filterDates: filterDates,
        };
      });
    }
    else{
      
      setBookingDetails((prev) => {
        return {
          ...prev,
          [name]: e?.target?.value,
        };
      });

      // if(name === "adult" || name === "children"){
      //   let guestperson = [];
      //     for (let i = 0; i < ((bookingDetails?.adult+ bookingDetails?.children) || person || 1); i++) {
      //       guestperson.push({ name: "", gender: "", age: "" });
      //     }
      //     setGuestCount(guestperson);
      // }
    }
  };

  useEffect(() => {
    if (bookingDetails?.from_date) {

      let guestperson = [];
          for (let i = 0; i < ((+bookingDetails?.adult+ +bookingDetails?.children) || person || 1); i++) {
            guestperson.push({ name: "", gender: "", age: "" });
          }
          setGuestCount(guestperson);
          
      const checkDateObj = new Date(bookingDetails?.from_date);
      const resultData = JSON.parse(packages?.special_price_date)?.find(
        (item) => {
          const fromDate = new Date(item.from_date);
          const toDate = new Date(item.to_date);
          return checkDateObj >= fromDate && checkDateObj <= toDate;
        }
      );
      console.log(resultData, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr111r");
      if (resultData && Object.keys(resultData).length) {
        let child = bookingDetails?.children ? +bookingDetails?.children : 0;
        let adult = bookingDetails?.adult ? +bookingDetails?.adult : 1;
        let spPrice = packages?.package_special_prices?.filter(
          (ps) => ps.person == child + adult && ps?.tourtype_id == packages?.tourtype_id
        );
        // console.log(spPrice,tourtype_id,"wwwwwwwwwwwwwwwwwwwwwwwwwww",packages?.package_special_prices,child,adult)
        if (!spPrice?.length) {
          spPrice = packages?.package_special_prices?.filter(
            (ps) => ps.person == 1 && ps?.tourtype_id == packages?.tourtype_id
          );

          let base_price =
            spPrice?.[0]?.offer_price * child +
            spPrice?.[0]?.offer_price * adult;
          let tax = (+base_price * settingData?.gst) / 100;
          setPackages((prev) => {
            return {
              ...prev,
              price: spPrice?.price,
              offer_price: spPrice?.offer_price,
              base_price: base_price,
              tax: tax,
              filterDates: packages?.package_dates || [],
            };
          });
        } else {
          let base_price = spPrice?.[0]?.offer_price;
          let tax = (+base_price * settingData?.gst) / 100;
          setPackages((prev) => {
            return {
              ...prev,
              price: spPrice?.price,
              offer_price: spPrice?.offer_price,
              base_price: base_price,
              tax: tax,
              filterDates: packages?.package_dates || [],
            };
          });
        }
      } else {
        let child = bookingDetails?.children ? bookingDetails?.children : 0;
        let adult = bookingDetails?.adult ? bookingDetails?.adult : 1;
        let person = +child + +adult;
        let resp = packages?.package_prices?.filter(
          (pp) => +pp.person === +person && +pp.tourtype_id === +packages?.tourtype_id
        );
        if (resp?.length) {
          let base_price = resp?.[0]?.offer_price;
          let tax = (+base_price * settingData?.gst) / 100;
          setPackages((prev) => {
            return {
              ...prev,
              price: resp?.[0]?.price,
              offer_price: resp?.[0]?.offer_price,
              base_price: base_price,
              tax: tax,
              filterDates: packages?.package_dates || [],
            };
          });
        } else {
          let base_price =
            packages?.offer_price *
              (bookingDetails?.children ? bookingDetails?.children : 0) +
            packages?.offer_price *
              (bookingDetails?.adult ? bookingDetails?.adult : 1);
          let tax = (+base_price * settingData?.gst) / 100;
          setPackages((prev) => {
            return {
              ...prev,
              price: packages?.price,
              offer_price: packages?.offer_price,
              base_price: base_price,
              tax: tax,
              filterDates: packages?.package_dates || [],
            };
          });
        }
      }
    }
  }, [
    bookingDetails?.adult,
    bookingDetails?.children,
    bookingDetails?.from_date,
    packages?.offer_price,
    packages?.package_special_prices,
    packages?.price,
    settingData?.gst,
  ]);

  const changeTerms = (e) => {
    setCheckout(e?.target?.checked);
  };

  const preview = () => {
    console.log(guestCount, bookingDetails, "qqqqqqqqqqqqqqqqqqqqqqq");
    // const isAnyFieldEmpty = guestCount.some((obj) =>
    //   Object.values(obj).some(
    //     (value) => value === "" || value === null || value === undefined
    //   )
    // );
    let bookingInfo = Object.values(bookingDetails).some(
      (value) => value === "" || value === null || value === undefined
    );
    if ( bookingInfo) {
      toast.warning("Please fill all fields");
      setIsModalVisible(false);
      return;
    }
    setIsModalVisible(true);
  };

  const confirmBooking = async () => {
    setIsModalVisible(false);
    ApiService.post("package-booking", {
      ...bookingDetails,
      package_id: packages?.id,
      //guests: guestCount,
      base_price: packages?.base_price,
      tax: packages?.tax,
    })
      .then((data) => {
        toast.success(data?.message);
        setTimeout(() => {
          setIsThankModalVisible(true)
          //window.location.href = `/fix-package-details/${packages?.url}`;
        }, 2000);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loader">
            <img src="https://i.gifer.com/4V0b.gif" alt="Loading..." />
          </div>
        </div>
      )}

      <section className="page-titles">
        <div className="container-fluid px-lg-5">
          <div className="check-out-page-heading">
            <h1>Complete Your Booking</h1>
          </div>
        </div>
      </section>
      <section className="details-package-checkout">
        <div className="container-fluid px-lg-5">
          <div className="details-package-checkout-inner">
            <div className="row">
              <div className="col-lg-9">
                <div className="inner-page-box-checkout mb-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="inner-packages-image">
                        <img
                          src={`${Image_PATH}Package/${packages?.image_url}`}
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="package-incluision-box-fixed">
                        <div className="fixed-inner-package-date">
                          <span>{packages?.duration}</span>
                        </div>
                        <div className="inner-package-name-fixed">
                          <h4>{packages?.title}</h4>
                        </div>
                        <div className="inner-package-dest-fixed">
                          <p>
                            <b> Destination: </b> Arrive
                            {packages?.destination_cover}e{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-page-box-checkout mb-3">
                  <div className="booking-details">
                    <h5>
                      <span> Enter Booking Details</span>
                    </h5>
                  </div>
                  <div className="checkout-form">
                    <form className="mt-3" action="">
                      <div className="row ">
                        {packages?.booking_type === "1" && (
                          <>
                            <div className="col-md-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                Month
                              </label>
                              <select
                                onChange={(e) => changeBookingFiled(e, "month")}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected="">Select Month</option>
                                {result &&
                                  result?.length > 0 &&
                                  result?.map((r, k) => {
                                    return (
                                      <option
                                        selected={
                                          bookingDetails?.month === r?.index
                                        }
                                        value={r?.index}
                                      >
                                        {r?.month}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="col-md-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                From Date
                              </label>
                              {/* <input
                                onChange={(e) =>
                                  changeBookingFiled(e, "from_date")
                                }
                                type="date"
                                id="inputPassword5"
                                className="form-control"
                                value={bookingDetails?.from_date}
                                readOnly={true}
                              /> */}

                              <select
                                onChange={(e) =>
                                  changeBookingFiled(e, "from_date")
                                }
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected="">Select Date</option>
                                {packages?.filterDates &&
                                  packages?.filterDates?.length > 0 &&
                                  packages?.filterDates?.map((r, k) => {
                                    return (
                                      <option
                                        selected={
                                          bookingDetails?.from_date ===
                                          r?.from_date
                                        }
                                        value={r?.from_date}
                                      >
                                        {r?.from_date}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </>
                        )}
                        {packages?.booking_type === "0" && (
                          <div className="col-md-3">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              From Date
                            </label>
                            <input
                              onChange={(e) =>
                                changeBookingFiled(e, "from_date")
                              }
                              type="date"
                              id="inputPassword5"
                              className="form-control"
                              value={bookingDetails?.from_date}
                            />
                          </div>
                        )}
                        {/* <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            To Date
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "to_date")}
                            type="date"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.to_date}
                            readOnly={true}
                          />
                        </div> */}
                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Adults (Above 12 years) *
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "adult")}
                            type="number"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.adult}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Children (5-12 years)
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "children")}
                            type="number"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.children}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Name
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "name")}
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="Enter Full Name"
                            value={bookingDetails?.name}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Phone No.
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "phone")}
                            type="tel"
                            id="inputPassword5"
                            className="form-control"
                            placeholder={11223462}
                            value={bookingDetails?.phone}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Email Id.
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "email")}
                            type="email"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="example@gmail.com"
                            value={bookingDetails?.email}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Country
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "country")}
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="India"
                            value={bookingDetails?.country}
                            disabled={!bookingDetails?.from_date}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="inner-page-box-checkout mb-3">
                  <div className="booking-details">
                    <h5>
                      <span> Traveller Details</span>
                    </h5>
                  </div>
                  <div className="checkout-form">
                    <form action="" id="dynamic-form">
                      {guestCount?.length > 0 &&
                        guestCount.map((g, k) => {
                          return (
                            <div className="row mt-4" key={k}>
                              <div className="gets-number">
                                <h6>(Guest {k + 1})</h6>
                              </div>
                              <div className="col-md-2">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Gender
                                </label>
                                <select
                                  onChange={(e) => changeFiled(e, "gender", k)}
                                  className="form-select"
                                  aria-label="Default select example"
                                  disabled={!bookingDetails?.from_date}
                                >
                                  <option selected="">Gender</option>
                                  <option
                                    selected={
                                      g?.gender === "Male" ? "selected" : false
                                    }
                                    value={"Male"}
                                  >
                                    Male
                                  </option>
                                  <option
                                    selected={
                                      g?.gender === "Female"
                                        ? "selected"
                                        : false
                                    }
                                    value={"Female"}
                                  >
                                    Female
                                  </option>
                                  <option
                                    selected={
                                      g?.gender === "Others"
                                        ? "selected"
                                        : false
                                    }
                                    value={"Others"}
                                  >
                                    Others
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Full name
                                </label>
                                <input
                                  disabled={!bookingDetails?.from_date}
                                  onChange={(e) => changeFiled(e, "name", k)}
                                  type="text"
                                  id="inputPassword5"
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  value={g?.name}
                                />
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Age
                                </label>
                                <input
                                  disabled={!bookingDetails?.from_date}
                                  onChange={(e) => changeFiled(e, "age", k)}
                                  type="number"
                                  id="inputPassword5"
                                  className="form-control"
                                  value={g?.age}
                                />
                              </div>
                              {k > 0 && (
                                <button
                                disabled={!bookingDetails?.from_date}
                                  type="button"
                                  onClick={() => removeGuest(k)}
                                  class="btn btn-danger delete-btn"
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          );
                        })}
                    </form>
                    <button
                    disabled={!bookingDetails?.from_date}
                      type="button"
                      onClick={() => addMoreGuest()}
                      className="btn btn-primary"
                    >
                      Add More Guest
                    </button>
                  </div>
                </div> */}
                <div className="check-box mt-3">
                  <div className="mb-3 form-check">
                    <input
                      onChange={(e) => changeTerms(e)}
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Check me out
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="left-side-content">
                  <div className="details-price-box-chackout">
                    <div className="fixed-price">
                      <h5 className="mb-0">Starting from</h5>
                      <hr />
                      <div className="base-price">
                        <span>Base Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" /> {packages?.base_price}
                        </h6>
                      </div>
                      <hr />
                      <div className="tax-included">
                        <span>Tax Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" />{" "}
                          {packages?.tax ? Math.round(packages?.tax, 2) : 0}
                        </h6>
                      </div>
                      <hr />
                      <div className="Total-price">
                        <span>Total Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" />{" "}
                          {packages?.tax
                            ? packages?.base_price +
                              Math.round(packages?.tax, 2)
                            : 0}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="apply-copen">
                    <div className="apply-text">
                      <h5>Apply Coupons</h5>
                    </div>
                    <div className="apply-input">
                      <input
                        type="text"
                        id="inputPassword5"
                        className="form-control"
                        placeholder="Enter Promo Code"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="continue-btn">
                <button
                  disabled={!checkOut}
                  className="btn btn-continue"
                  type="button"
                  onClick={() => preview()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PreviewBooking
        isModalVisible={isModalVisible}
        guestCount={guestCount}
        bookingDetails={bookingDetails}
        confirmBooking={confirmBooking}
        setIsModalVisible={setIsModalVisible}
      />

<BookingThanks
        isModalVisible={isThanksModalVisible}
        setIsModalVisible={setIsThankModalVisible}
      />
    </>
  );
}
