import React, { useEffect, useState } from 'react'
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { Image_PATH } from '../../config/constant';

export default function CancellationPolicy() {
 const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
    useEffect(() => {
      getMeta();
    }, []);

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "cancellation",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

    useEffect(() => {
      // If metaTags is not empty, inject it into the head
      if (metaTags) {
        const head = document.head;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = metaTags;
        // Inject all meta tags into the head
        Array.from(tempDiv.children).forEach((child) => {
          head.prepend(child);
        });
  
        const body = document.body;
        const tempDivbody = document.createElement("div");
        tempDivbody.innerHTML = page?.footer;
        // Inject all meta tags into the head
        Array.from(tempDivbody.children).forEach((child) => {
          body.append(child);
        });
      }
    }, [metaTags]);
  return (
    <>
    <section className="banner-section">
      <div className="banner">
        <img src={`${Image_PATH}page/${page?.banner}`} alt={page?.alt_banner}  width="100%" />
      </div>
    </section>
    {/* banner section end  */}
    <>
  {/* Hello world */}
  <div className="term-condition-sec py-5">
  <div className="container-fluid px-lg-5">
    <div className="title">
      <h2>CANCELLATION POLICY</h2>
    </div>
    <div className="terms-data mt-3">
      <p>
        <span style={{ color: "#ff6600" }}>
          <strong>CANCELLATION POLICY: -</strong>
        </span>
      </p>
      <ul>
        <li>
          20% Charge of the total Package Cost On booking amendment/Plan Change.
        </li>
        <li>
          30% cancellation Charge of the package cost after confirmation/issuing
          the hotel voucher.
        </li>
        <li>
          Cancellations made 45 days before departure Chargeable 30% of the
          Package Cost
        </li>
        <li>
          Cancellations made 35 to 44 days before departure 40% Chargeable of
          the Package Cost
        </li>
        <li>
          Cancellations made 22 to 34 days before departure 50% Chargeable of
          the Package Cost
        </li>
        <li>
          100% Chargeable of the total package cost 14 to 21 days before
          departure
        </li>
        <li>No. flexibility at the time of Travel in the plan.</li>
        <li>
          Any train and flight tickets cancellation will be applicable as per
          Indian railway / airline rules and refund/adjustment can be given only
          after receiving the refund from train or airline company.
        </li>
        <li>
          No cancellation &amp; any refund will be made at any cost in case of
          bad weather or landslide and natural digester, or terrorism and which
          is beyond the control of the age. we will heartly help you in that
          situation which is possible from our side .
        </li>
        <li>
          Cancellation &amp; other policies as per the hotel/company rule.
        </li>
        <li>All cancellation are to be communicated in written by mail.</li>
        <li>
          Any amendment in the original booking u have to update in written by
          mail.
        </li>
        <li>
          There are no refund due to any pandemic or virous attack in that
          situation we can amend your travel date for the future booking .
        </li>
        <li>Advance Given Amount For Booking Non Refundable</li>
      </ul>
      <h5>INSURANCE</h5>
      <ul>
        <li>
          Travel Insurance only Offered on your Request it's covered by third
          Party.
        </li>
        <li>Cancellation chargeable will be applicable as per the policy.</li>
      </ul>
      <h5>REFUND AFTER CANCELLATION</h5>
      <ul>
        <li>
          The Refund after cancellation of the booking will be take 14 working
          days from the cancellation date. if you cancelling any
          Railway/Airline/Volvo ticket's all refund will be procced after
          receiving by the respective Airline/service provider
        </li>
      </ul>
    </div>
  </div>
</div>

</>

  </>
  )
}
