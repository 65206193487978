/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import Select from "react-select";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
export default function IndiaTour() {
  const [Destination, setDestination] = useState([]);
  const [selectDestination, setSelectDestination] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [Themes, setThemes] = useState([]);
  const [banners, setBanners] = useState([]);
  const [popularPackage, setPopularPackage] = useState([]);
  const [filterForm, setFilterForm] = useState({
    destination_id: 0,
    theme_id: 0,
  });
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  useEffect(() => {
    getDestinationList();
    getThemesList();
    getBannerList();
    getPopularPackageList();
    getMeta();
  }, []);

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "india",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    if (Destination.length > 0) {
      let dest = [];
      Destination?.map((d) => {
        dest.push({ value: d?.id, label: d?.name });
      });
      setSelectDestination(dest);
    }
  }, [Destination]);

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", {
      page: 1,
      limit: 10000,
      type: "india",
    })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getPopularPackageList = async () => {
    ApiService.post("package/get-data", {
      page: 1,
      limit: 1500,
      type: "india",
      popular: "1",
    })
      .then((data) => {
        if (data?.Packages) {
          setPopularPackage(data.Packages);
        } else {
          setPopularPackage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", {
      page: 1,
      limit: 1000,
      type: "india",
    })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
          let theme = [];
          data.Themess?.map((d) => {
            theme.push({ value: d?.id, label: d?.name });
          });
          setSelectTheme(theme);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getBannerList = async () => {
    ApiService.post("banner/get-data", {
      page: 1,
      limit: 6,
      status: "1",
      type: "2",
    })
      .then((data) => {
        if (data?.Banners) {
          setBanners(data.Banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const setChangeEvent = async (e, name) => {
    setFilterForm((prev) => {
      return {
        ...prev,
        [name]: e?.value,
      };
    });
  };

  const getPackage = () => {
    let destination = Destination?.filter(
      (dt) => dt?.id === filterForm?.destination_id
    );
    let theme_id = Themes?.filter((dt) => dt?.id === filterForm?.theme_id);
    window.location.href = `/package-list/filter/${destination?.[0]?.url}/${theme_id?.[0]?.url}`;
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  const options1 = {
    items: 1, // Only show one item at a time
    loop: true, // Allow looping
    margin: 10, // Add some margin between items
    responsive: {
      0: {
        items: 1, // 1 item on smaller screens
      },
      600: {
        items: 1, // 1 item on medium screens
      },
      1000: {
        items: 1, // 1 item on larger screens
      },
    },
    nav: true, // Enable navigation buttons
    dots: true, // Enable dots navigation
  };

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src={`${Image_PATH}page/${page?.banner}`} alt="" width="100%" />
        </div>
        {/* search form start  */}
        <div className="search-form">
          <div className="search-form-box">
            <div className="form">
              <div className="form-inputs">
                <div className="search-form-input-1" id="first-select">
                  <i className="fa fa-map-marker" />
                  {/* <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
              </select> */}
                  <Select
                    id="Cityselect"
                    options={selectDestination}
                    placeholder="Select..."
                    onChange={(e) => setChangeEvent(e, "destination_id")}
                  />
                </div>
                <div className="search-form-input-2" id="second-select">
                  <i className="fa fa-flag-o" />
                 

                  <Select
                    id="Tourselect"
                    options={selectTheme}
                    placeholder="Select..."
                    onChange={(e) => setChangeEvent(e, "theme_id")}
                  />
                </div>
                <div className="search-form-button">
                  <button
                    onClick={() => getPackage()}
                    className="btn btn-search text-light"
                  >
                    Search <i className="fa fa-send-o" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* search form end  */}
      </section>
      {/* banner section end  */}
      {/* destination area start  */}
      <section className="destination">
        <div className="container-fluid">
          <div className="title">
            <h2>Experience India’s Magic, Minto Style</h2>
            <p>
            Where Every Journey Becomes a Beautiful Memory!
            </p>
          </div>
          <div className="destionation-boxes mt-5">
            <div className="row">
              {Destination &&
                Destination?.length > 0 &&
                Destination?.map((d, k) => {
                  return (
                    <div className="col-lg-2 col-md-4 col-6 mb-5" key={k}>
                      <div className="destionation-box">
                        <a href={`package-list/india/${d?.url}`}>
                          <div className="destination-img">
                            <img
                              src={`${Image_PATH}destination/${d?.image_url}`}
                              alt=""
                              width="100%"
                            />
                          </div>
                          <div className="destionation-text">
                            <h5> {d?.name} </h5>
                            <p>{d?.packages?.[0]?.count} Tour Package</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {/* destination area start  */}
      {/* special travels area start  */}
      <section className="special-travels pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Travel Specialization</h2>
            <p>
            Where Your Travel Dreams Meet Our Expertise!
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="row">
                {Themes &&
                  Themes?.length > 0 &&
                  Themes?.map((d, k) => {
                    let content = [];

                    // A for loop to create multiple elements for each item
                    for (let i = 0; i < +d?.star_rating; i++) {
                      content.push(<i className="fa fa-star" />);
                    }

                    return (
                      <div className="col-lg-4 col-md-6 col-12 mb-3">
                        <div className="special-packages">
                          <a href={`package-list-by-themes/india/${d?.url}`}>
                            <div className="special-images">
                              <img
                                src={`${Image_PATH}Themes/${d?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="special-text">
                              <div className="special-text-name">
                                <h6>{d?.name}</h6>
                                <p>{d?.packages?.[0]?.count} Tour Packages</p>
                              </div>
                              <div className="special-text-review">
                                <span>{content}</span>
                                <p>{d?.total_review} Review</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="special-banner">
                <div className="slider">
                  {banners &&
                                                          banners?.length > 0 &&
                                                          banners
                                                            ?.filter((b) => b.type === "2")?.length>0 && (
                                                      <OwlCarousel
                                                      {...options1}
                                                        className="owl-carousel owl-theme"
                                                        
                                                      >
                                                        {banners &&
                                                          banners?.length > 0 &&
                                                          banners
                                                            ?.filter((b) => b.type === "2")
                                                            ?.map((tm,k) => {
                                                              return (
                                                                <div className="item" key={k}>
                                                                  { tm?.url ?  <Link to={`${tm?.url}`}>
                                                                  <img
                                                                    src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                                    alt={tm?.alt_image}
                                                                    width="100%"
                                                                  />
                                                                  </Link> : <img
                                                                    src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                                    alt={tm?.alt_image}
                                                                    width="100%"
                                                                  /> }
                                                                </div>
                                                              );
                                                            })}
                                                        {/* <div className="item">
                                                      <img src="assets/images/b-2.jpg" alt="" width="100%" />
                                                    </div>
                                                    <div className="item">
                                                      <img src="assets/images/b-3.jpg" alt="" width="100%" />
                                                    </div> */}
                                                      </OwlCarousel>
                                                            )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area end  */}
      {/* popular tour area start  */}
      <section className="popular-tour pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Most Popular Tours</h2>
            <p>
            Wherever Your Heart Desires, We’ll Take You There! Checkout Our Package Below
            </p>
          </div>
          <div className="row pt-5">
            {popularPackage &&
              popularPackage?.length > 0 &&
              popularPackage?.map((p, k) => {
                return (
                  <div className="col-lg-4 col-12 mb-3" key={k}>
                    <Link to={`/package-details/${p?.url}`}>
                      <div className="popular-packages py-2 ">
                        <div className="row">
                          <div className="col-lg-12 col-md-6 col-12">
                            <div className="row popular-packages-box m-1">
                              <div className="col-4">
                                <div className="popular-package-img">
                                  <img
                                    src={`${Image_PATH}Package/${p?.image_url}`}
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="popular-package-text">
                                  <h5>{p?.title}</h5>
                                  <span>{p?.duration}</span>
                                  <p>{p?.destination_cover}</p>
                                  <h4>
                                    <i className="fa fa-inr" />{" "}
                                    {p?.offer_price || 0}/-{" "}
                                    <span>Per Person</span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            {/* <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/1.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/5.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/9.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 ">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/2.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/3.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/4.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/6.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/7.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/8.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/10.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/11.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/12.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> */}
          </div>
        </div>
      </section>
      {/* popular tour area end  */}
    </>
  );
}
