import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { Image_PATH } from '../../config/constant';
import axios from 'axios';
export default function Footer() {
  const [email,setEmail]=useState("");
  const [isValid,setIsValid]=useState(false);
  const [settingData,setSettingsData]=useState({});
  const [Destination,setDestination]=useState([]);
  let initialEnq={name:"",email:"",phone:"",city_name:"",comment:""}
  const [enquiryData,setEnquiryData]=useState(initialEnq);
  const [banners,setBanners]=useState([]);
  useEffect(()=>{
    getSettings();
    getDestinationList();
    getBannerList();
  },[])
const changeEmail=(e)=>{
  setEmail(e?.target?.value);
  let emailId=e?.target?.value;
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   if(regex.test(emailId)){
    setIsValid(true);
   }else{
    setIsValid(false);
   }
}

const getSettings=async ()=>{
  ApiService.get('settings/1').then((data)=>{
    if(data?.data){
      setSettingsData(data.data);
    }else{
      setSettingsData({});
    }
  }).catch((err)=>{
    toast.error(err?.response?.data);
  })
}

const subscibeEmail=()=>{
  if(!email){
    toast.error("Email Not Valid");
    return;
  }
  setIsValid(false);
  ApiService.post('subscribe',{email}).then((data)=>{
    if(data?.message){
      toast.success(data?.message)
      setEmail("");
    }else{
      toast.success("Something Went Worng")
    }
  }).catch((err)=>{
    toast.error(err?.response?.data);
  })
}

const getDestinationList=async ()=>{
  ApiService.post('destination/get-data-type',{page:1,limit:10000}).then((data)=>{
    if(data?.Destinations){
     
      setDestination(data.Destinations);
    }else{
      setDestination([]);;
    }
  }).catch((err)=>{
    toast.error(err?.response?.data);
  })
}


const changeInput=async(e,name)=>{
  setEnquiryData((prevState)=>{
    return {
    ...prevState,
    [name]: e?.target?.value
    }
  })
}

const submitEnquiry=()=>{
  const currentUrl = window.location.href;
  // if(!enquiryData?.email || !validateEmail(enquiryData?.email)){
  //   toast.error("Email Not Valid");
  //   return;
  // }
  if(!enquiryData?.phone ){
    toast.error("Mobile Not Valid");
    return;
  }

  const formData = new FormData();
  formData.append('name', enquiryData?.name);
  formData.append('email', enquiryData?.email || "");
  formData.append('phonenumber', enquiryData?.phone);
  formData.append('state', enquiryData?.city_name || "");
  formData.append('domain', currentUrl);
  formData.append('description', enquiryData?.comment || "");

  axios.post('https://www.mintoholidays.co.in/crm/welcome/saveEnquiry', formData)
  .then(response => {
    console.log('Response:', response.data);
  })
  .catch(error => {
    console.error('There was an error!', error);
  });

  ApiService.post('enquiry',enquiryData).then((data)=>{
    if(data?.message){
      toast.success(data?.message)
      setEnquiryData(initialEnq)
    }else{
      toast.success("Something Went Worng")
    }
  }).catch((err)=>{
    toast.error(err?.response?.data);
  })
}

const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

const validateMobileNumber = (number) => {
  // Regular expression for exactly 10 digits
  const regex = /^\d{10}$/;
  return regex.test(number);
};

const getBannerList=async ()=>{
  ApiService.post('banner/get-data',{page:1,limit:600,status:'1',type:'4'}).then((data)=>{
    if(data?.Banners){
      setBanners(data.Banners);
    }else{
      setBanners([]);
    }
  }).catch((err)=>{
  //  toast.error(err?.response?.data);
  })
}
  return (
   <>

<div
  className="modal fade"
  id="exampleModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header" id="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel" />
        <button
          type="button"
          className="btn-close modal-button-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="row">
        <div className="col-lg-6" id="mobile-modal-hide">
          <div className="modal-body">
            <div className="modal-banner-content">
              <div className="modal-banner">
                <div
                  id="carouselExampleRide"
                  className="carousel slide carousel-fade"
                  data-bs-ride="true"
                >
                  <div className="carousel-inner">
                  { banners && banners?.length>0 && banners?.map((tm,k)=>{
                        return(
                    <div className={ k ==0 ? `carousel-item active` : 'carousel-item'} key={k}>
                      {tm?.url ? <Link to={`${tm?.url}`}>
                        <img
                          src={`${Image_PATH}Banner/${tm?.image_url}`} alt={tm?.alt_image}
                          className="d-block w-100"
                          
                        /></Link> : <img className="d-block w-100" src={`${Image_PATH}Banner/${tm?.image_url}`} alt={tm?.alt_image} />
                      }
                      
                    </div>
                     )
                    })}
                    <div className="carousel-item">
                      <a href="#">
                        <img
                          src="assets/images/modal-banner/2.jpg"
                          className="d-block w-100"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="carousel-item">
                      <a href="#">
                        <img
                          src="assets/images/modal-banner/3.jpg"
                          className="d-block w-100"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-features">
                <div className="why-features row py-3">
                  <div className="features-box col-4">
                    <div className="features-box-icon">
                      <img
                        src="assets/images/icons/traveler.png"
                        alt=""
                        width="45px"
                      />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>{settingData?.happy_travellers}</h4>
                      <span>Happy Traveler</span>
                    </div>
                  </div>
                  <div className="features-box col-4" id="features-box-border">
                    <div className="features-box-icon">
                      <img src="assets/images/icons/loyalty.png" alt="" width="45px" />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>{settingData?.tour_success}</h4>
                      <span>Tours Success</span>
                    </div>
                  </div>
                  <div className="features-box col-4">
                    <div className="features-box-icon">
                      <img
                        src="assets/images/icons/experience.png"
                        alt=""
                        width="45px"
                      />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>{settingData?.positive_review}</h4>
                      <span>Positives Review </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="last-line">
                  <p className="text-center">
                    <b>Excellent!</b>{" "}
                    <span>
                      {" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle-o" />{" "}
                    </span>{" "}
                    {settingData?.review_text || ""}
                  </p>
                </div>
              </div>
              <div className="modal-contact">
                <div className="call-modal">
                  <h6>
                    <a href={`tel:${settingData?.mobile}`}>
                      <span>
                        <i className="fa fa-phone" /> Call Us :{" "}
                      </span>{" "}
                      {settingData?.mobile}
                    </a>
                  </h6>
                  <h6>
                    <a href={`mailto:${settingData?.email}`}>
                      <span>
                        <i className="fa fa-envelope" /> Send Us Mail :{" "}
                      </span>{" "}
                      {settingData?.email}
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6" id="pop-right">
          <div className="modal-body" id="right-body">
            <div className="popup-form">
              <h5>Get in touch with us</h5>
              <form action="">
                <div className="col mb-3">
                  <input
                    value={enquiryData?.name}
                    type="text"
                    className="form-control"
                    id="Name"
                    placeholder="Enter Full Name *"
                    onChange={(e)=>changeInput(e,'name')}
                  />
                </div>
                <div className="col mb-3">
                  <input
                    value={enquiryData?.phone}
                    type="number"
                    className="form-control"
                    id="Phone"
                    placeholder="Enter Phone No. *"
                    onChange={(e)=>changeInput(e,'phone')}
                  />
                </div>
                <div className="col mb-3">
                  <input
                    value={enquiryData?.email}
                    type="email"
                    className="form-control"
                    id="Email"
                    placeholder="Enter Email Id *"
                    onChange={(e)=>changeInput(e,'email')}
                  />
                </div>
                <div className="col mb-3">
                  <textarea
                    value={enquiryData?.comment}
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: 100 }}
                    defaultValue={""}
                    onChange={(e)=>changeInput(e,'comment')}
                  />
                </div>
                <div className="col- mb-3">
                  <button type='button' onClick={()=>submitEnquiry()} className="btn btn-submit">Submit Enquiry</button>
                  {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                </div>
              </form>
            </div>
            <div className="get-whatsapp">
              <a href={`https://api.whatsapp.com/send?phone=918800542270&text=hello`} target="_blank">
                <span>
                  <img src="assets/images/icons/whatsapp.png" alt="" />
                </span>
                <h6>Get itenary On Whatsapp</h6>
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <div className="quick_contact">
    <a
      href={`https://api.whatsapp.com/send?phone=918800542270&text=hello`}
      className="qwhtsapp"
      target="_blank"    >
      <i className="fa fa-whatsapp quick_contact_icon" />
    </a>
    <a href={`tel:${settingData?.mobile}`} className="qcall" target="_blank">
      <i className="fa fa-phone quick_contact_icon" />
    </a>
  </div>
  <div className="qiuck-enquiry">
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Enquiry Now
    </button>
  </div>


    <footer className="pt-5">
    <div className="container-fluid px-lg-5">
      <div className="footer-top pb-4">
        <div className="footer-logo">
          <a href="index.html">
            <img src="assets/images/logo-white.png" alt="" width="250px" />
          </a>
        </div>
        <div className="rating py-2">
          <p>
            <span>
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </span>{" "}
            {settingData?.review_text}
          </p>
        </div>
        <div className="language-select py-2">
          {/* <span>
            <img src="assets/images/icons/globe.png" alt="" />
          </span>
          <div className="language">
            <select>
              <option value="default">English</option>
              <option>Hindi</option>
            </select>
          </div> */}
        </div>
      </div>
      <div className="footer-bottom py-5">
        <div className="row">
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Contact Info</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">{settingData?.address}</a>
                  </li>
                  <li>
                    <h5>
                      <a href="#">{settingData?.mobile}</a>{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>
                      <a href="#">{settingData?.tele}</a>{" "}
                    </h5>
                  </li>
                  <li>
                    <a href="#">{settingData?.email}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Our Company</h5>
              </div>
              <div className="footer-links">
                <ul className='footl'>
                <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/india-tour">India Tour</Link>
                  </li>
                  <li>
                    <Link to="/international-tour">International Tour</Link>
                  </li>
                  <li>
                    <Link to="/theme-base">Theme Based</Link>
                  </li>
                  <li>
                    <Link to="/fixed-departures">Fix Departure</Link>
                  </li>

                  <li>
                    <Link to="/payment">Payment</Link>
                  </li>

                  <li>
                    <Link to="/guest-feedback">Guest Feedback</Link>
                  </li>

                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>India Tour</h5>
              </div>
              <div className="footer-links">
              <ul className='footl'>
                  { Destination && Destination?.length>0 && Destination?.filter((d)=>d.type==='india')?.slice(0,10)?.map((di,k)=>{
                    return(
                      <li key={k}>
                        <a href={`package-list/india/${di?.url}`}>{ di?.name }</a>
                      </li>
                    )
                  })}
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Internationals Tours</h5>
              </div>
              <div className="footer-links">
                <ul className='footl'>
                  { Destination && Destination?.length>0 && Destination?.filter((d)=>d.type==='international')?.slice(0,10)?.map((di,k)=>{
                    return(
                      <li key={k}>
                        <a href={`package-list/international/${di?.url}`}>{ di?.name }</a>
                      </li>
                    )
                  })}
                  {/* <li>
                    <a href="#">Himachal Pradesh</a>
                  </li>
                  <li>
                    <a href="#">Uttarakhand</a>
                  </li>
                  <li>
                    <a href="#">Uttar Pradesh</a>
                  </li>
                  <li>
                    <a href="#">Goa</a>
                  </li>
                  <li>
                    <a href="#">Rajasthan</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Our Newsletter</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">
                      Save up to 50% on tours! Get exclusive access to members
                      only deal by email
                    </a>
                  </li>
                  <div className="newsletter-input">
                    <input type="text" value={email} onChange={(e)=>changeEmail(e)} placeholder="Email address" />
                    <button disabled={!isValid} onClick={()=>subscibeEmail()}>
                      <i className="fa fa-arrow-right " /> Subscribe
                    </button>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section py-4">
        <div className="copyright">
          <p>
            Copyright © 2024 <Link to="/">Mintoholidays.</Link> All Right
            reserved
          </p>
        </div>
        <div className="footer-follow-link">
          <span>
            <a href="https://www.facebook.com/mintoholidays" target='_blank' rel="noreferrer">
              <i className="fa fa-facebook" />
            </a>
            <a href="https://x.com/ashokkumar4447" target='_blank' rel="noreferrer">
              <i className="fa fa-twitter" />
            </a>{" "}
            <a href="https://www.instagram.com/MintoHolidays/" target='_blank' rel="noreferrer">
              <i className="fa fa-instagram" />
            </a>{" "}
            <a href="https://in.pinterest.com/Mintoholidays/_saved/" target='_blank' rel="noreferrer">
              <i className="fa fa-pinterest" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/ashokkumar4447/"><i class="fa fa-linkedin"></i></a>
            <a href="https://www.youtube.com/channel/UCqIMQg_OmSyuEJAbWQ5SNaQ/featured" target='_blank' rel="noreferrer">
              <i className="fa fa-youtube-play" />
            </a>
          </span>
        </div>
        <div className="payments">
          <img src="assets/images/icons/payment.png" alt="" />
        </div>
      </div>
    </div>
  </footer>
  <div className="footer-pages">
    <div className="container-fluid px-lg-5 pt-4">
      <div className="footer-pages-link">
        <ul>
        <li>
            <Link to="cancellation-policy">Cancellation Policy </Link>
          </li>
          <li>
            <Link to="/privacy-poicy">Privacy Policy </Link>
          </li>
          <li>
            <Link to="term-condition">Terms and Conditions </Link>
          </li>
          <li>
            <Link to="/payment-poicy">Payment Policy </Link>
          </li>
         
        </ul>
      </div>
    </div>
  </div>
   </>
  )
}
