/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import Pagination from "../../elements/Pagination";
import { Image_PATH } from "../../config/constant";
export default function GuestFeedback() {
  let initialEnq = {
    name: "",
    email: "",
    phone: "",
    city_name: "",
    comment: "",
    rating: 1,
  };
  const [Feedback, setFeedback] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [enquiryData, setEnquiryData] = useState(initialEnq);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  useEffect(() => {
    getFeedbackList();
    getMeta();
  }, []);

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "feedback",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getFeedbackList = async () => {
    ApiService.post("Feedback/get-data", {
      page: currentPage + 1,
      limit: 9,
      status: "1",
      is_approved: "1",
    })
      .then((data) => {
        if (data?.enquiries) {
          setFeedback(data.enquiries);
          setPageCount(data?.pagination?.totalPages);
        } else {
          setFeedback([]);
          setPageCount(0);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handlePageClick = (selectedPage) => {
    const clickedPage = selectedPage.selected;
    if (clickedPage !== currentPage) {
      setCurrentPage(clickedPage);
    }
  };

  const changeInput = async (e, name) => {
    setEnquiryData((prevState) => {
      return {
        ...prevState,
        [name]: e?.target?.value,
      };
    });
  };

  const submitEnquiry = () => {
    if (!enquiryData?.email || !validateEmail(enquiryData?.email)) {
      toast.error("Email Not Valid");
      return;
    }
    if (!enquiryData?.phone || !validateMobileNumber(enquiryData?.phone)) {
      toast.error("Mobile Not Valid");
      return;
    }
    ApiService.post("feedback", enquiryData)
      .then((data) => {
        if (data?.message) {
          toast.success(data?.message);
          setEnquiryData(initialEnq);
        } else {
          toast.success("Something Went Worng");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    // Regular expression for exactly 10 digits
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src={`${Image_PATH}page/${page?.banner}`} alt="" width="100%" />
        </div>
      </section>
      {/* banner section end  */}
      <section className="contact pb-5">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-md-5">
              <div className="feedback-box p-2">
                <div className="feedback-box-title">
                  <div className="Feedback-title pt-5">
                    <h4 className="text-center">
                    {page?.banner_title}
                    </h4>
                  </div>
                </div>
                <div className="feedback-content mt-3 ">
                  <p>
                  {page?.banner_content}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 " id="feedbox-form-box">
              <div className="contact-page-form pt-5" id="feedbox-form-box">
                <div className="title">
                  <h3 className="text-justify" id="feedback-title">
                    Send Your Feedback
                  </h3>
                </div>
                <form className="mt-4" action="">
                  <div className="row">
                    <div className="mb-3 col-6 contact-input">
                      <i className="fa fa-user-o" />
                      <input
                        value={enquiryData?.name}
                        type="text"
                        className="form-control"
                        id="Name"
                        placeholder="Enter Full Name *"
                        onChange={(e) => changeInput(e, "name")}
                      />
                    </div>
                    <div className="mb-3 col-6 contact-input">
                      <i className="fa fa-volume-control-phone" />
                      <input
                        value={enquiryData?.phone}
                        type="tel"
                        className="form-control"
                        id="Phone"
                        placeholder="Enter Phone No. *"
                        onChange={(e) => changeInput(e, "phone")}
                      />
                    </div>
                    <div className="mb-3 col-6 contact-input">
                      <i className="fa fa-envelope-o" />
                      <input
                        value={enquiryData?.email}
                        type="email"
                        className="form-control"
                        id="Email"
                        placeholder="Enter Email Id *"
                        onChange={(e) => changeInput(e, "email")}
                      />
                    </div>
                    <div className="mb-3 col-6 contact-input">
                      <i className="fa fa-map-marker" />
                      <input
                        value={enquiryData?.city_name}
                        type="text"
                        className="form-control"
                        id="text"
                        placeholder="Enter City Name *"
                        onChange={(e) => changeInput(e, "city_name")}
                      />
                    </div>

                    <div className="mb-3 col-6 contact-input">
                      <i className="fa fa-star" />
                      <select
                      style={{"text-align": "center"}}
                        value={enquiryData?.rating}
                        className="form-control"
                        placeholder="Enter Rating 1-5 *"
                        onChange={(e) => changeInput(e, "rating")}
                      >
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
                        </select>
                    </div>

                    <div className="mb-3 col-12 contact-input">
                      <i className="fa fa-pencil-square-o" />
                      <textarea
                        value={enquiryData?.comment}
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: 100 }}
                        defaultValue={""}
                        onChange={(e) => changeInput(e, "comment")}
                      />
                    </div>
                  </div>
                  <div className="more-details-btn">
                    <button
                      onClick={() => submitEnquiry()}
                      type="button"
                      className="btn btn-more-detaisl"
                    >
                      <i className="fa fa-send-o" /> Submit Your Feedback
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="all-feedbacks py-5">
        <div className="container-fluid px-lg-5">
          <div className="title">
            <h2>Voices of Our Travelers</h2>
            <p>Share Your Story, Shape the Journey – Because Every Experience Matters!</p>
          </div>
          <div className="row mt-5">
            {Feedback &&
              Feedback?.length > 0 &&
              Feedback?.map((fd, k) => {
                let content = [];

                // A for loop to create multiple elements for each item
                for (let i = 0; i < +fd?.rating; i++) {
                  content.push(<i className="fa fa-star" />);
                }
                return (
                  <div className="col-md-4 mb-3" key={k}>
                    <div className="all-feedback-box" id="bg-green">
                      <div className="client-img">
                        <div className="user-img">
                          <div className="user-icon">
                            <img
                              src="assets/images/icons/users.jpg"
                              alt=""
                              style={{ width: 60 }}
                            />
                          </div>
                          <div className="user-name">
                            <h5>{fd?.name}</h5>
                            {/*<span>Students</span> */}
                          </div>
                        </div>
                        <div className="stars py-2">{content}</div>
                      </div>
                      <div className="review-para pt-2">
                        <p className="feedback-content">{fd?.comment}</p>
                        {/* <button className="read-more-btn">Read More... </button> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p className="feedback-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.{" "}
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.{" "}
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div> */}
          </div>
          <div className="pagignation-box">
            <nav aria-label="Page navigation example">
              {/* <ul className="pagination">
            <li className="page-item ">
              <a className="page-link end" href="#">
                <i className="fa fa-angle-left" />
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                ....
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fa fa-angle-right" />
              </a>
            </li>
          </ul> */}
              <Pagination
                className="pagination"
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
