import React, { useState } from "react";
import axios from "axios";

const PaymentForm = () => {
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");

  const handlePayment = async () => {
    try {
      const response = await axios.post("http://localhost:5000/api/generate-payment-url", {
        order_id: orderId,
        amount: amount,
      });

      const paymentDetails = response.data;

      // Redirect to CCAvenue payment page with the payment details
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"; // CCAvenue payment URL

      for (const key in paymentDetails) {
        if (paymentDetails.hasOwnProperty(key)) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = paymentDetails[key];
          form.appendChild(input);
        }
      }

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Payment initiation failed", error);
    }
  };

  return (
    <div>
      <h2>Make a Payment</h2>
      <input
        type="text"
        placeholder="Order ID"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
      />
      <input
        type="text"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PaymentForm;
