import { BrowserRouter as Router, useLocation } from "react-router-dom";
import './App.css';
import AppRoutes from './routes/routes';
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./component/common/ScrollToTop";

function Layout({ children }) {
  const location = useLocation();
  return (
    <>
     <ToastContainer position="top-right" />
    <Header /> 
    {children}
    <Footer/>
    </>
  );
}

function App() {
  return (
  
<Router basename="/">
  <ScrollToTop/>
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
}

export default App;
