import React from 'react'
import ReactPaginate from 'react-paginate';
export default function Pagination({pageCount,handlePageClick}) {
  return (
    <div>
        <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
    </div>
  )
}
