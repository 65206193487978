/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from "react-select";
export default function ThemeBased() {
  const [Themes, setThemes] = useState([]);
  const [Destination, setDestination] = useState([]);
  const [selectDestination, setSelectDestination] = useState([]);
    const [selectTheme, setSelectTheme] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [filterForm, setFilterForm] = useState({
      destination_id: 0,
      theme_id: 0,
    });
  useEffect(() => {
    getThemesList();
    getDestinationList();
    getMeta();
    getDestinationList();
    getThemesList();
  }, []);

  useEffect(() => {
    if (Destination.length > 0) {
      let dest = [];
      Destination?.map((d) => {
        dest.push({ value: d?.id, label: d?.name });
      });
      setSelectDestination(dest);
    }
  }, [Destination]);

  

  const setChangeEvent = async (e, name) => {
    setFilterForm((prev) => {
      return {
        ...prev,
        [name]: e?.value,
      };
    });
  };

  const getPackage = () => {
    let destination = Destination?.filter(
      (dt) => dt?.id === filterForm?.destination_id
    );
    let theme_id = Themes?.filter((dt) => dt?.id === filterForm?.theme_id);
    
    if(theme_id?.length > 0){
      window.location.href = `/package-list/filter/${destination?.[0]?.url}/${theme_id?.[0]?.url}`;
    }else
      window.location.href = `/package-list/filter/${destination?.[0]?.url}`;
  };

  const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    dots: true,
    nav: true,
    autoplayTimeout: 3000,
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "theme_based",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", { page: 1, limit: 1000 })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
          let theme = [];
          data.Themess?.map((d) => {
            theme.push({ value: d?.id, label: d?.name });
          });
          setSelectTheme(theme);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", { page: 1, limit: 10000 })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      {/* banner section  */}
      <section className="banner-section">
        <div className="banner">
          <img src={`${Image_PATH}page/${page?.banner}`} alt="" width="100%" />
        </div>


         {/* search form start  */}
         <div className="search-form">
          <div className="search-form-box">
            <div className="form">
              <div className="form-inputs">
                <div className="search-form-input-1" id="first-select">
                  <i className="fa fa-map-marker" />
                  {/* <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
              </select> */}
                  <Select
                    id="Cityselect"
                    options={selectDestination}
                    placeholder="Select..."
                    onChange={(e) => setChangeEvent(e, "destination_id")}
                  />
                </div>
                <div className="search-form-input-2" id="second-select">
                  <i className="fa fa-flag-o" />
                 

                  <Select
                    id="Tourselect"
                    options={selectTheme}
                    placeholder="Select..."
                    onChange={(e) => setChangeEvent(e, "theme_id")}
                  />
                </div>
                <div className="search-form-button">
                  <button
                    onClick={() => getPackage()}
                    className="btn btn-search text-light"
                  >
                    Search <i className="fa fa-send-o" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* search form end  */}
      </section>
      {/* banner section end  */}
      {/* special travels area start  */}

      <section className="page-titles">
        <div className="container-fluid ">
          <div className="page-head px-lg-3">
            <h1>{page?.banner_title}</h1>
            <p>
            {page?.banner_content}
            </p>
          </div>
        </div>
      </section>

      <section className="special-travels">
        <div className="container-fluid">
          {/* <div className="title">
            <h2>{page?.banner_title}</h2>
            <p>
            {page?.banner_content}
            </p>
          </div> */}
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row">
                {Themes &&
                  Themes?.length > 0 &&
                  Themes?.map((th, k) => {
                    let content = [];

                    // A for loop to create multiple elements for each item
                    for (let i = 0; i < +th?.star_rating; i++) {
                      content.push(<i className="fa fa-star" />);
                    }

                    return (
                      <div className="col-lg-4 col-md-6 col-12 mb-3" key={k}>
                        <div className="special-packages">
                          <Link to={`/package-list-by-themes/${th?.url}`}>
                            <div className="special-images">
                              <img
                                src={`${Image_PATH}Themes/${th?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="special-text">
                              <div className="special-text-name">
                                <h6>{th?.name}</h6>
                                <p>
                                  {th?.package_themes?.[0]?.package?.count || 0} Tour Packages
                                </p>
                              </div>
                              <div className="special-text-review">
                                <span>{content}</span>
                                <p>{th?.total_review} Review</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
               
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area end  */}
      {/*international destination area start  */}
      <section className="destination-international">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Best Tour Destinations</h2>
            <p>
            Because You Deserve the Best of Every Destination!
            </p>
          </div>
          <div className="intern-boxes mt-5">
            <div className="slider">
              {Destination && Destination?.length > 0 && (
                <OwlCarousel
                  options={options}
                  className="owl-carousel owl-theme"
                >
                  {Destination &&
                    Destination?.length > 0 &&
                    Destination?.map((dt, k) => {
                      return (
                        <div className="item" key={k}>
                          <div className="intern-box">
                            <Link to={`/package-list/${dt?.url}`}>
                              <div className="destination-img">
                                <img
                                  src={`${Image_PATH}destination/${dt?.image_theme ? dt?.image_theme : dt?.image_url}`}
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <div className="inter-text">
                                <h5> {dt?.name} </h5>
                                <p>
                                  {dt?.packages?.[0]?.count || 0} Tour Package
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}

                  
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* destination area start  */}
    </>
  );
}
